import { gql } from "@apollo/client";

export default gql`
  query {
    blogs(pagination: { start: 0, limit: 1 }, sort: ["createdAt"]) {
      data {
        id
        attributes {
          createdAt
        }
      }
    }
  }
`;
