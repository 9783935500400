import { useQuery } from "@apollo/client";
import React from "react";
import TeamQry from "../gql/TeamQry";
import styled from "styled-components";

const TeamMemberWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (min-width: 768px) {
    width: 25%;
  }

  @media only screen and (min-width: 1024px) {
    width: 20%;
  }

  img {
    border-radius: 50%;
    width: 60%;
    height: auto;

    @media only screen and (min-width: 768px) {
      width: 80%;
    }
  }

  .text {
    text-align: center;
    text-transform: uppercase;

    .name {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
`;

const TeamMember = ({ member }) => {
  const { first_name, last_name, role, img } = member?.attributes;
  const { url } = img?.data.attributes;

  return (
    <TeamMemberWrapper>
      <img src={url} alt="" />
      <div className="text">
        <p className="name">
          {first_name} {last_name}
        </p>
        <p>{role}</p>
      </div>
    </TeamMemberWrapper>
  );
};

function Team() {
  const { data, loading } = useQuery(TeamQry);

  const members = data?.teams.data;

  const renderMembers = () => {
    if (loading) return <p>Loading...</p>;

    return members?.map((member) => (
      <TeamMember member={member} key={member.id} />
    ));
  };

  /* class names inherit styles from about page */
  return (
    <>
      <div className="header">The Team</div>
      <div className="members">{renderMembers()}</div>
    </>
  );
}

export default Team;
