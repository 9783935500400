import { gql } from "@apollo/client";

export default gql`
  query {
    contributors(pagination: { limit: 20 }) {
      data {
        attributes {
          first_name
          last_name
          contribution
        }
      }
    }
  }
`;
