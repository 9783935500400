import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { navlinks } from "../navLinks";
import swal from "sweetalert";

const Wrapper = styled.div`
  display: none;

  @media only screen and (min-width: 1024px) {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid lightgrey;
    display: flex;
    height: 4rem;
    position: absolute;
    top: 6.55rem;
    padding: 0 7.5rem;
    width: calc(100% - 15rem);

    .nav-links {
      display: flex;
      font-weight: 500;
      justify-content: space-between;
      margin: 0 auto;
      width: 60%;

      .link {
        color: black;
        text-decoration: none;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    padding: 0 10rem;
    width: calc(100% - 20rem);
  }
`;

const renderNavLinks = () => {
  return navlinks.map((link) => {
    if (link.path === "#") {
      return (
        <NavLink
          onClick={() => swal("This section is still under construction.")}
          className="link"
          key={link.id}
          to={link.path}
        >
          {link.name}
        </NavLink>
      );
    }

    return (
      <NavLink className="link" key={link.id} to={link.path}>
        {link.name}
      </NavLink>
    );
  });
};

function Navbar() {
  return (
    <Wrapper>
      <div className="nav-links">{renderNavLinks()}</div>
    </Wrapper>
  );
}

export default Navbar;
