import Archives from "components/features/Archives/Archives";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function ArchivesPage() {
  return (
    <AppLayout>
      <Archives />
    </AppLayout>
  );
}

export default ArchivesPage;
