import React from "react";
import Container from "components/common/Container";
import styled from "styled-components";
import backgroundImg from "assets/featured-blog-img.jpg";
import rLogo from "assets/r-logo-white.png";
import riseLogo from "assets/r-logo-white.png";
import fbIcon from "assets/socmed-fb-white.png";
import igIcon from "assets/socmed-ig-white.png";
import twIcon from "assets/socmed-tw-white.png";
import ytIcon from "assets/socmed-yt-white.png";
import { navlinks } from "./navLinks";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  background-image: url(${backgroundImg});
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  color: white;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media only screen and (min-width: 1024px) {
    padding-top: 5rem;
  }
`;

const FooterContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }

  .r-logo-container {
    height: 6.5rem;
    margin-bottom: 2.5rem;

    @media only screen and (min-width: 1024px) {
      height: 7.5rem;
      margin-right: 5rem;
      margin-bottom: 0;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media only screen and (min-width: 1024px) {
      align-items: flex-start;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 0.25fr) minmax(0, 1fr);
      gap: 5rem;
    }
  }

  .column-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .content {
    color: rgba(255, 255, 255, 0.5);
  }

  .pages-link-container {
    display: flex;
    flex-direction: column;
  }

  .pages-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0.125rem;
    width: fit-content;
  }

  .socmed-links {
    display: flex;
    gap: 0.65rem;
  }

  .socmed-logo {
    height: 2rem;
  }
`;

const CopyrightContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  width: 100%;

  .rise-logo {
    height: 2rem;
    margin-bottom: 1rem;
  }
`;

const footerPageLinks = () => {
  return navlinks.map((link) => (
    <NavLink className="pages-link" key={link.id} to={link.path}>
      {link.name}
    </NavLink>
  ));
};

function Footer() {
  return (
    <Wrapper>
      <Container>
        <div className="footer-container">
          <FooterContentContainer>
            <div className="r-logo-container">
              <img src={rLogo} alt="" />
            </div>
            <div className="grid">
              <div className="about column">
                <p className="column-title">About Us</p>
                <p className="about-content content">
                  RISE Magazine is a unique magazine that focuses on the sport
                  of personal self development. It targets an audience that
                  ranges from all demographics, gender, ages, vocations and
                  educational backgrounds. Readers are taken through a journey
                  using a modern approach to self improvement.
                </p>
              </div>
              <div className="pages column">
                <p className="column-title">Pages</p>
                <div className="pages-link-container">{footerPageLinks()}</div>
              </div>
              <div className="socials column">
                <p className="column-title">Socials</p>
                <div className="socmed-links">
                  <a
                    href={`${process.env.REACT_APP_FB_LINK}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fbIcon} alt="fb" className="socmed-logo" />
                  </a>
                  <a
                    href={`${process.env.REACT_APP_IG_LINK}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={igIcon} alt="ig" className="socmed-logo" />
                  </a>
                  <a
                    href={`${process.env.REACT_APP_TW_LINK}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twIcon} alt="tw" className="socmed-logo" />
                  </a>
                  <a
                    href={`${process.env.REACT_APP_YT_LINK}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ytIcon} alt="yt" className="socmed-logo" />
                  </a>
                </div>
              </div>
            </div>
          </FooterContentContainer>
          <CopyrightContainer>
            <img className="rise-logo" src={riseLogo} alt="" />
            <p>All Rights Reserved 2022 | Rise Magazine</p>
          </CopyrightContainer>
        </div>
      </Container>
    </Wrapper>
  );
}

export default Footer;
