import AppLayout from "components/layout/AppLayout";
import React from "react";
import Blog from "components/features/Blog/Blog";

function BlogPage() {

  return (
    <AppLayout>
      <Blog />
    </AppLayout>
  );
}

export default BlogPage;
