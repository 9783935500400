import React from "react";
import Container from "components/common/Container";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import BlogCategoriesQry from "../../common/gql/BlogCategoriesQry";
import { getImgUrl } from "utils/getImgUrl.util";
import { Link } from "react-router-dom";
import useStores from "stores/useStores";

const Wrapper = styled.div`
  .blog-categories-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 1rem 0;

    @media only screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 1.5rem;
      margin: 2rem 0;
    }
  }

  .category {
    align-items: center;
    background-position: center;
    background-size: cover;
    border-radius: 1rem;
    color: white;
    display: flex;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    height: 7rem;
    letter-spacing: 0.1rem;
    word-break: break-word;
    text-decoration: none;
    padding: 1rem;
  }
`;

function BlogCategories() {
  const { uiStore } = useStores();
  const { setArchivesFilter, setBlogIds } = uiStore;

  const { data } = useQuery(BlogCategoriesQry);

  const handleClick = (id) => {
    setBlogIds(id);
    setArchivesFilter({
      filters: {
        category: {
          id: {
            in: [id],
          },
        },
      },
    });
  };

  const blogCategories = () => {
    return data?.categories.data.map((category, index) => {
      const imgUrl = getImgUrl(category?.attributes.image.data.attributes.url);

      const style = {
        backgroundImage: `
      linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url(${imgUrl})`,
      };

      return (
        <Link
          to="/archives"
          key={index}
          className="category"
          style={style}
          onClick={() => handleClick(category.id)}
        >
          {category?.attributes.category}
        </Link>
      );
    });
  };

  return (
    <Container>
      <Wrapper>
        <div className="section-title-container">
          <p className="section-title">Blog Categories</p>
          <hr />
        </div>
        <div className="blog-categories-container">{blogCategories()}</div>
      </Wrapper>
    </Container>
  );
}

export default BlogCategories;
