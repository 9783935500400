import React, { useState } from "react";
import logo from "assets/r-logo-word.svg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import authorImg from "assets/author-img-placeholder.png";
import { AiOutlineMenu } from "react-icons/ai";
import MobileNavbar from "../MobileNavbar";

const Wrapper = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  position: fixed;
  height: 5.5rem;
  width: calc(100% - 4rem);
  z-index: 99;
  top: 0;

  .div {
    display: flex;
    width: 33%;
  }

  .start {
    justify-content: flex-start;

    .icon {
      padding: 0.5rem;
      border: 1px solid #130f85;
      border-radius: 50%;
    }
  }

  .center {
    justify-content: center;
  }

  .end {
    justify-content: flex-end;

    img {
      width: 1.75rem;
      height: auto;
      padding: 0.25rem;
      border: 1px solid #130f85;
      border-radius: 50%;
    }
  }

  .logo {
    height: 3.75rem;
    width: auto;
  }

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

function MobileTopbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  return (
    <Wrapper>
      <div className="div start">
        <AiOutlineMenu
          className="icon"
          color="#130f85"
          onClick={() => setIsMobileMenuOpen(true)}
        />
      </div>
      <div className="div center">
        <NavLink to="/">
          <img src={logo} alt="rise-logo" className="logo" />
        </NavLink>
      </div>
      <div className="div end">
        <img src={authorImg} alt="ig" />
      </div>
      <MobileNavbar isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
    </Wrapper>
  );
}

export default MobileTopbar;
