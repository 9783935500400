import { gql } from "@apollo/client";

export default gql`
  query blogs($filters: BlogFiltersInput, $pagination: PaginationArg) {
    blogs(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          createdAt
          title
          author
          preview_img {
            data {
              attributes {
                url
              }
            }
          }
          category {
            data {
              attributes {
                category
              }
            }
          }
          Slug
        }
      }
    }
  }
`;
