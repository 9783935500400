import React, { useEffect } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import mobileMenuHeader from "assets/mobile-menu-header.png";
import { navlinks } from "../navLinks";
import { NavLink } from "react-router-dom";
import { fbIcon, igIcon, twIcon, ytIcon } from "components/common/socmedIcons";
import riseLogo from "assets/rise-logo.png";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;

  .menu {
    width: 65vw;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;

    @media only screen and (min-height: 667px) and (max-width: 767px) {
      width: 75vw;
    }

    @media only screen and (min-width: 768px) {
      width: 50vw;
    }

    .mobile-menu-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .close {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      img {
        width: 100%;
      }
    }

    .mobile-menu-body {
      padding: 1.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @media only screen and (min-height: 667px) and (max-width: 767px) {
        padding: 3rem;
      }

      .mobile-menu-links {
        display: flex;
        flex-direction: column;
        gap: 0.35rem;

        .pages-link-container {
          display: flex;
          flex-direction: column;
          gap: 0.35rem;

          .pages-link {
            color: black;
            font-size: 0.75rem;
            font-weight: 500;
            text-decoration: none;
            width: fit-content;

            @media only screen and (min-height: 667px) and (max-width: 767px) {
              font-size: 0.85rem;
            }

            @media only screen and (min-width: 768px) {
              font-size: 1.25rem;
            }
          }
        }
      }

      .socmed-links {
        display: flex;
        gap: 0.5rem;
        width: 100%;

        @media only screen and (min-width: 768px) {
          gap: 1rem;
        }

        .socmed-logo {
          width: 1.5rem;

          @media only screen and (min-height: 667px) and (max-width: 767px) {
            width: 2rem;
          }

          @media only screen and (min-width: 768px) {
            width: 3rem;
          }
        }
      }

      .trademark {
        display: none;

        @media only screen and (min-height: 667px) {
          display: initial;

          .rise-logo {
            margin-bottom: 1rem;
            width: 75%;
          }

          .trademark-text {
            font-size: 0.6rem;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);

            @media only screen and (min-width: 768px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
`;

function MobileNavbar({ isOpen, setIsOpen }) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const mobileNavlinks = () => {
    return navlinks.map((link) => (
      <div className="pages-link-container" key={link.id}>
        <NavLink className="pages-link" to={link.path}>
          {link.name}
        </NavLink>
        <hr />
      </div>
    ));
  };

  if (isOpen) {
    return (
      <Wrapper>
        <div className="menu">
          <div className="mobile-menu-header">
            <img src={mobileMenuHeader} alt="" />
            <AiOutlineClose
              className="close"
              onClick={() => setIsOpen(false)}
              color="white"
              size="1.25rem"
            />
          </div>
          <div className="mobile-menu-body">
            <div className="mobile-menu-links">{mobileNavlinks()}</div>
            <div className="socmed-links">
              <a
                href={`${process.env.REACT_APP_FB_LINK}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fbIcon} alt="" className="socmed-logo" />
              </a>
              <a
                href={`${process.env.REACT_APP_IG_LINK}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={igIcon} alt="" className="socmed-logo" />
              </a>
              <a
                href={`${process.env.REACT_APP_TW_LINK}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twIcon} alt="" className="socmed-logo" />
              </a>
              <a
                href={`${process.env.REACT_APP_YT_LINK}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ytIcon} alt="" className="socmed-logo" />
              </a>
            </div>
            <div className="trademark">
              <img src={riseLogo} alt="" className="rise-logo" />
              <p className="trademark-text">
                All Rights Reserved 2022 • RISE Magazine
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default MobileNavbar;
