import { makeAutoObservable } from "mobx";

export default class UiStore {
  archivesFilter = {};

  blogIds = [];

  constructor() {
    makeAutoObservable(this);
  }

  setArchivesFilter = (filters) => (this.archivesFilter = filters || {});

  setBlogIds = (blogIds) => (this.blogIds = [blogIds]);
}
