import React from "react";
import styled from "styled-components";
import authorImgPlaceholder from "assets/author-img-placeholder.png";
import { getImgUrl } from "utils/getImgUrl.util";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  background-position: center;
  background-size: cover;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 20rem;
  margin: 0 auto;
  padding: 1.5rem;
  width: 85%;

  @media only screen and (min-width: 768px) {
    height: 17.5rem;
    width: 80%;
  }

  &:hover {
    cursor: pointer;
  }

  .blog-category {
    background-color: white;
    border-radius: 1rem;
    font-size: 14px;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
    width: fit-content;
  }

  .blog-info {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: auto;
    text-align: center;

    .blog-title {
      font-size: 1.5rem;

      @media only screen and (min-width: 768px) {
      font-size: 1.25rem;
      }
    }

    .author-details {
      align-items: center;
      display: flex;
      justify-content: center;

      img {
        width: 1.75rem;
        height: auto;
        margin-right: 0.5rem;
      }

      p {
        font-weight: 300;
      }

      .author-name {
        font-weight: 600;
      }
    }

    .blog-interactions {
    }
  }
`;

function BlogCard({ data }) {
  const navigate = useNavigate();

  const blogCardData = data?.attributes;
  const slug = blogCardData?.Slug;

  const imgUrl = getImgUrl(blogCardData?.preview_img.data.attributes.url);

  const style = {
    backgroundImage: `
  linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url(${imgUrl})`,
  };

  const redirectToBlog = () => navigate(`/archives/${slug}`);

  return (
    <Wrapper style={style} onClick={redirectToBlog}>
      <p className="blog-category">
        {blogCardData?.category?.data.attributes.category}
      </p>
      <div className="blog-info">
        <p className="blog-title">{blogCardData?.title}</p>
        <div className="author-details">
          <img src={authorImgPlaceholder} alt="" />
          <p>
            By <span className="author-name">{blogCardData?.author}</span>
          </p>
        </div>
        {
          //TODO: views & comments
          /* <hr />
        <p className="blog-interactions">Interaction Details</p> */
        }
      </div>
    </Wrapper>
  );
}

export default BlogCard;
