import React from "react";
import AppLayout from "components/layout/AppLayout";
import HeroCarousel from "components/features/Home/HeroCarousel";
import FeaturedBlogs from "components/features/Home/FeaturedBlogs";
import TrendingBlogs from "components/features/Home/TrendingBlogs";
import BlogCategories from "components/features/Home/BlogCategories";
import Reviews from "components/features/Home/Reviews";
import FeaturedAuthors from "components/features/Home/FeaturedAuthors";

function HomePage() {
  return (
    <AppLayout>
      <HeroCarousel />
      <FeaturedBlogs />
      <TrendingBlogs />
      <BlogCategories />
      <Reviews />
      <FeaturedAuthors />
    </AppLayout>
  );
}

export default HomePage;
