import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        font-family: "Roboto", sans-serif;
        margin: 0;
        padding: 0;
        
        .section-title {
            text-transform: uppercase;
            font-family: "Prompt", "Roboto", sans-serif;
            font-size: 2rem;
            letter-spacing: 0.1rem;
        }
    }
`;

export default GlobalStyle;
