import About from "components/features/About";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function AboutPage() {
  return (
    <AppLayout>
      <About />
    </AppLayout>
  );
}

export default AboutPage;
