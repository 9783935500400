import React from "react";
import { useQuery } from "@apollo/client";
import BlogsQry from "./gql/BlogsQry";
import BlogCard from "components/features/Archives/BlogCard";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import useStores from "stores/useStores";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1920px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

function Blogs() {
  const { uiStore } = useStores();
  const { archivesFilter } = uiStore;

  const { data } = useQuery(BlogsQry, { variables: archivesFilter });

  const blogs = data?.blogs.data.map((blog) => (
    <BlogCard key={blog?.id} data={blog} />
  ));

  return <Wrapper>{blogs?.length ? blogs : "No blogs found"}</Wrapper>;
}

export default observer(Blogs);
