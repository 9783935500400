import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import MobileTopbar from "./MobileTopbar";
import Footer from "./Footer";

const Wrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    padding-top: 10.5rem;
  }
`;

function AppLayout({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <MobileTopbar />
      <Topbar />
      <Navbar />
      <Wrapper>{children}</Wrapper>
      <Footer />
    </div>
  );
}

export default AppLayout;
