import Container from "components/common/Container";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import TrendingBlogsQry from "./gql/TrendingBlogsQry";
import TrendingBlogCard from "./TrendingBlogCard";
import { Swiper, SwiperSlide } from "swiper/react";

const TrendingBlogsContainer = styled.div`
  width: 95%;
  margin: 0 auto;

  .blog-card-container {
    margin-top: 1rem;

    @media only screen and (min-width: 1024px) {
      margin-top: 2rem;
    }
  }
`;

function TrendingBlogs() {
  const { data, loading } = useQuery(TrendingBlogsQry);
  const [slidesToShow, setSlidesToShow] = useState(
    window.innerWidth < 768 ? 1 : 3
  );

  const trendingBlogsData = data?.trendingBlogs.data;

  const blogs = trendingBlogsData?.map((tBlog, index) => {
    return (
      <SwiperSlide
        key={tBlog.attributes.blog.data.id}
        className="blog-card-container"
      >
        <TrendingBlogCard key={index} data={tBlog.attributes.blog.data} />
      </SwiperSlide>
    );
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
        return;
      }

      return blogs?.length < 3
        ? setSlidesToShow(blogs?.length)
        : setSlidesToShow(3);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [blogs?.length, loading]);
  
  return (
    <Container>
      <TrendingBlogsContainer>
        <div className="section-title-container">
          <p className="section-title">Trending Blogs</p>
          <hr />
        </div>
        <Swiper slidesPerView={slidesToShow} loop={true} spaceBetween={16}>
          {blogs}
        </Swiper>
      </TrendingBlogsContainer>
    </Container>
  );
}

export default TrendingBlogs;
