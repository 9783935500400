import { gql } from "@apollo/client";

export default gql`
  query {
    categories {
      data {
        id
        attributes {
          category
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
