import { gql } from "@apollo/client";

export default gql`
  query featuredBlogs($pagination: PaginationArg) {
    featuredBlogs (pagination: $pagination) {
      data {
        attributes {
          blog {
            data {
              id
              attributes {
                createdAt
                title
                author
                preview_img {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                category {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                content_preview
                Slug
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
