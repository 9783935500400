import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { getImgUrl } from "utils/getImgUrl.util";
import authorImgPlaceholder from "assets/author-img-placeholder.png";
import BlogQry from "./gql/BlogQry";

const Wrapper = styled.div`
  margin: 0;

  .header-img {
    .blog-img {
      height: 20rem;
      width: 100%;
      object-fit: cover;
      margin: 0;
    }
  }

  .blog-content-container {
    padding: 2rem;

    @media only screen and (min-width: 1024px) {
      padding: 3rem 20rem;
    }

    @media only screen and (min-width: 1920px) {
      padding: 3rem 25rem;
    }
  }

  .blog-category {
    background-color: #2b3990;
    border-radius: 1rem;
    color: white;
    font-size: 14px;
    margin: 2rem 0;
    padding: 0.5rem 0.75rem;
    width: fit-content;
    position: absolute;
    margin-top: -4.5rem;
    z-index: 99;
  }

  .blog-title {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2.5rem;
  }

  .author-details {
    align-items: center;
    display: flex;
    justify-content: center;

    .author-img {
      height: auto;
      margin-right: 1rem;
      width: 2.5rem;
    }

    .author-name {
      font-weight: 600;
    }
  }

  .content-container {
    margin-top: 5rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .content-section {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .text-subtitle {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .text-content {
      text-align: justify;
      line-height: 1.35rem;
    }
  }

  .media-container {
    display: flex;
    gap: 4rem;
    width: 100%;

    .media-item {
      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
  }
`;

function Blog() {
  const { slug } = useParams();

  const { data } = useQuery(BlogQry, {
    variables: {
      filters: {
        Slug: {
          eq: slug,
        },
      },
    },
  });

  const blog = data?.blogs.data[0];
  const blogData = blog?.attributes;
  const blogContent = blogData?.content;
  const blogMedia = blogData?.images.data;

  const renderContent = () =>
    blogContent?.map((cont) => {
      return (
        <div key={cont.id} className="content-section">
          {cont.subtitle && <p className="text-subtitle">{cont.subtitle}</p>}
          <p className="text-content">{cont.text_content}</p>
        </div>
      );
    });

  const renderMedia = () => {
    if (blogMedia?.length < 1) return null;

    const media = blogMedia?.map((media) => {
      return (
        <div className="media-item" key={media.id}>
          <img src={media.attributes.url} alt="" />
        </div>
      );
    });

    return media;
  };

  return (
    <Wrapper>
      <div className="header-img">
        <img
          className="blog-img"
          src={getImgUrl(blogData?.preview_img.data.attributes.url)}
          alt=""
        />
      </div>
      <div className="blog-content-container">
        <p className="blog-category">
          {blogData?.category?.data?.attributes?.category}
        </p>
        <h1 className="blog-title">{blogData?.title}</h1>
        <div className="author-details">
          <img className="author-img" src={authorImgPlaceholder} alt="" />
          <p>
            By <span className="author-name">{blogData?.author}</span> -{" "}
            {new Date(blogData?.createdAt).toLocaleDateString()}
          </p>
        </div>
        <div className="content-container">{renderContent()}</div>
        <div className="media-container">{renderMedia()}</div>
      </div>
    </Wrapper>
  );
}

export default Blog;
