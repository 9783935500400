import React from "react";
import Container from "components/common/Container";
import styled from "styled-components";
import authorPlaceholderImg from "assets/author-img-placeholder.png";
import verifiedBadge from "assets/verified-badge.png";
import bannerImg1 from "assets/featured-author-banner-1.jpg";
import bannerImg2 from "assets/featured-author-banner-2.jpg";
import bannerImg3 from "assets/featured-author-banner-3.jpg";
import bannerImg4 from "assets/featured-author-banner-4.jpg";
import bannerImg5 from "assets/featured-author-banner-5.jpg";
import riseIconR from "assets/rise-icon-r.svg";
import riseIconCircle from "assets/rise-icon-circular.svg";
import { riseIconographyAnimation } from "./FeaturedBlogs";

const Wrapper = styled.div`
  ${riseIconographyAnimation}

  .icon,
  .icon-circle {
    top: -3.75rem;
    left: 3.75rem;
  }
`;

const FeaturedAuthorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @media only screen and (min-width: 768px) {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 2rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

const FeaturedAuthor = styled.div`
  margin: 0 auto;
  width: 85%;

  @media only screen and (min-width: 768px) {
    width: 100%;
  }

  .featured-author-details {
    background: white;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 1rem;
    padding-top: 2.5rem;
    position: relative;
    top: -2rem;

    @media only screen and (min-width: 768px) {
      padding-top: 3.5rem;
      margin-bottom: -2rem;
    }

    hr {
      margin: 1rem 0;
      opacity: 0.35;
      width: 100%;
    }
  }

  .author-banner-img {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    height: 10rem;
    object-fit: cover;
    width: 100%;
  }

  .author-img-container {
    position: absolute;
    top: -2.5rem;
  }

  .verified-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1.5rem;

    @media only screen and (min-width: 768px) {
      height: 2rem;
    }
  }

  .author-img {
    outline: 4px solid white;
    border-radius: 50%;
    height: 4rem;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      height: 5rem;
    }
  }

  .author-name {
    font-size: 1.5rem;
    font-weight: 600;

    @media only screen and (min-width: 768px) {
      font-size: 2rem;
    }
  }

  .author-title {
    font-weight: 500;
    font-size: 0.9rem;

    @media only screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .author-interactions-container {
    margin: auto;
    text-align: center;
  }

  .author-interactions {
    font-size: 0.75rem;
    margin: 0.5rem 0;
  }
`;

const authorData = [
  {
    name: "AuthorName",
    title: "Author Title",
    blogs_published: 140,
    comments_posted: 58,
    img: authorPlaceholderImg,
    banner_img: bannerImg1,
  },
  {
    name: "AuthorName",
    title: "Author Title",
    blogs_published: 127,
    comments_posted: 53,
    img: authorPlaceholderImg,
    banner_img: bannerImg2,
  },
  {
    name: "AuthorName",
    title: "Author Title",
    blogs_published: 125,
    comments_posted: 49,
    img: authorPlaceholderImg,
    banner_img: bannerImg3,
  },
  {
    name: "AuthorName",
    title: "Author Title",
    blogs_published: 122,
    comments_posted: 45,
    img: authorPlaceholderImg,
    banner_img: bannerImg4,
  },
  {
    name: "AuthorName",
    title: "Author Title",
    blogs_published: 110,
    comments_posted: 37,
    img: authorPlaceholderImg,
    banner_img: bannerImg5,
  },
];

const authorCards = () => {
  return authorData.map((author, index) => {
    return (
      <FeaturedAuthor key={index}>
        <img className="author-banner-img" src={author.banner_img} alt="" />
        <div className="featured-author-details">
          <div className="author-img-container">
            <img className="author-img" src={author.img} alt="" />
            <img className="verified-badge" src={verifiedBadge} alt="" />
          </div>
          <p className="author-name">{author.name}</p>
          <p className="author-title">{author.title}</p>
          <hr />
          <div className="author-interactions-container">
            <p className="author-interactions">
              {author.blogs_published} Total Blogs Published
            </p>
            <p className="author-interactions">
              {author.comments_posted} Total Comments Posted
            </p>
          </div>
        </div>
      </FeaturedAuthor>
    );
  });
};

function FeaturedAuthors() {
  return (
    <Wrapper>
      <img src={riseIconR} alt="" className="icon" />
      <img src={riseIconCircle} alt="" className="icon-circle" />
      <Container>
        <div className="section-title-container">
          <p className="section-title">Featured Authors</p>
          <hr />
        </div>
        <FeaturedAuthorsContainer>{authorCards()}</FeaturedAuthorsContainer>
      </Container>
    </Wrapper>
  );
}

export default FeaturedAuthors;
