import Container from "components/common/Container";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useQuery } from "@apollo/client";
import FeaturedBlogsQry from "./gql/FeaturedBlogsQry";
import { getImgUrl } from "utils/getImgUrl.util";
import authorImgPlaceholder from "assets/author-img-placeholder.png";
import riseIconR from "assets/rise-icon-r.svg";
import riseIconCircle from "assets/rise-icon-circular.svg";
import { GrPrevious, GrNext } from "react-icons/gr";

export const spinKeyframes = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const spinAnimation = css`
  animation-name: spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const riseIconographyStyle = css`
  height: 7.5rem;
  position: absolute;
  bottom: -3.75rem;
  right: 3.75rem;
  width: auto;

  @media only screen and (max-width: 820px) {
    display: none;
  }
`;

export const riseIconographyAnimation = css`
  background-color: #efefef;
  position: relative;

  ${spinKeyframes}

  .icon,
  .icon-circle {
    ${riseIconographyStyle}
  }

  .icon-circle {
    ${spinAnimation}
  }
`;

const Wrapper = styled.div`
  ${riseIconographyAnimation}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const BlogInfo = styled.div`
  width: 100%;

  @media only screen and (min-width: 1024px) {
    width: 35%;
    margin-right: 2rem;
  }

  .blog-category {
    background-color: #2b3990;
    border-radius: 1rem;
    color: white;
    font-size: 14px;
    margin: 1rem auto;
    padding: 0.5rem 0.75rem;
    width: fit-content;

    @media only screen and (min-width: 1024px) {
      justify-content: initial;
      margin: 1rem 0;
    }
  }

  .blog-title {
    cursor: pointer;
    font-size: 2.5rem;
    margin-bottom: 1rem;

    @media only screen and (min-width: 1024px) {
      font-size: 4.5rem;
      margin-bottom: 2rem;
    }
  }

  .blog-description {
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;

    @media only screen and (min-width: 1024px) {
      font-size: 1rem;
    }
  }
`;

const AuthorInfo = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  margin: 1rem 0;

  @media only screen and (min-width: 1024px) {
    font-size: 1rem;
    margin: 2rem 0;
  }

  .author-img {
    height: auto;
    margin-right: 0.75rem;
    width: 2rem;

    @media only screen and (min-width: 1024px) {
      margin-right: 1rem;
      width: 2.5rem;
    }
  }

  .author-name {
    font-weight: 600;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0 auto;
  margin-top: 1rem;
  width: fit-content;

  @media only screen and (min-width: 1024px) {
    margin: 0;
    margin-top: 2rem;
  }

  .pagination-btn,
  .disabled-pagination-btn {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: default;
    }

    @media only screen and (min-width: 1024px) {
      width: 3rem;
      height: 3rem;
    }
  }

  .disabled-pagination-btn {
    background-color: lightgrey;
  }

  .pagination-icon {
    display: flex;
    font-size: 1rem;
    width: 100%;

    @media only screen and (min-width: 1024px) {
      font-size: 1.5rem;
    }
  }
`;

const BlogImgMobile = styled.div`
  margin-top: 1rem;
  width: 100%;

  .blog-img {
    border-radius: 3rem;
    cursor: pointer;
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
  }

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

const BlogImgDesktop = styled.div`
  display: none;

  @media only screen and (min-width: 1024px) {
    display: initial;
    width: 65%;

    .blog-img {
      border-radius: 3rem;
      cursor: pointer;
      width: 100%;
      height: 40rem;
      object-fit: cover;
    }
  }
`;

function FeaturedBlogs() {
  const navigate = useNavigate();

  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(1);
  const [total, setTotal] = useState(null);
  const { data, loading } = useQuery(FeaturedBlogsQry, {
    variables: {
      pagination: {
        start,
        limit,
      },
    },
  });

  const blogData = data?.featuredBlogs?.data[0].attributes.blog.data.attributes;
  const slug = blogData?.Slug;

  useEffect(() => {
    if (!loading) {
      setTotal(data?.featuredBlogs.meta.pagination.total);
    }
  }, [data?.featuredBlogs.meta.pagination.total, loading]);

  const handlePrevClick = () => {
    setStart(start - 1);
    setLimit(limit - 1);
  };

  const handleNextClick = () => {
    setStart(start + 1);
    setLimit(limit + 1);
  };

  const redirectToBlog = () => navigate(`/archives/${slug}`);

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <BlogInfo>
            <div className="section-title-container">
              <p className="section-title">Featured Content</p>
              <hr />
            </div>
            <BlogImgMobile onClick={redirectToBlog}>
              <img
                className="blog-img"
                src={getImgUrl(blogData?.preview_img.data.attributes.url)}
                alt=""
              />
            </BlogImgMobile>
            <p className="blog-category">
              {blogData?.category?.data?.attributes?.category}
            </p>
            <h1 className="blog-title" onClick={redirectToBlog}>
              {blogData?.title}
            </h1>
            <p className="blog-description" onClick={redirectToBlog}>
              {blogData?.content_preview}
            </p>
            <AuthorInfo>
              <img className="author-img" src={authorImgPlaceholder} alt="" />
              <p className="author-details">
                By <span className="author-name">{blogData?.author}</span> -{" "}
                {new Date(blogData?.createdAt).toLocaleDateString()}
              </p>
            </AuthorInfo>
            <hr />
            <ButtonsContainer>
              <button
                className={start ? "pagination-btn" : "disabled-pagination-btn"}
                disabled={!start}
                onClick={handlePrevClick}
              >
                <GrPrevious className="pagination-icon" />
              </button>
              <button
                className={
                  limit !== total ? "pagination-btn" : "disabled-pagination-btn"
                }
                disabled={limit === total}
                onClick={handleNextClick}
              >
                <GrNext className="pagination-icon" />
              </button>
            </ButtonsContainer>
          </BlogInfo>
          <BlogImgDesktop onClick={redirectToBlog}>
            <img
              className="blog-img"
              src={getImgUrl(blogData?.preview_img.data.attributes.url)}
              alt=""
            />
          </BlogImgDesktop>
        </ContentWrapper>
      </Container>
      <img src={riseIconR} alt="" className="icon" />
      <img src={riseIconCircle} alt="" className="icon-circle" />
    </Wrapper>
  );
}

export default FeaturedBlogs;
