import React from "react";
import styled, { css } from "styled-components";
import WhoWeAreImg from "assets/who-we-are.png";
import WhyReadRiseImg from "assets/why-read-rise.png";
import WhyWeDoItImg from "assets/why-we-do-it.png";
import OurVisionImg from "assets/our-vision.png";
import Contributors from "./Contributors";
import Team from "./Team";

export const headerStyle = css`
  background-color: black;
  color: white;
  display: flex;
  font-size: 1.75rem;
  justify-content: center;
  padding: 0.5rem 0;

  @media only screen and (min-width: 768px) {
    font-size: 2.75rem;
    padding: 2rem 0;
  }
`;

const Wrapper = styled.div`
  padding-top: 5.5rem;

  @media only screen and (min-width: 1024px) {
    padding-top: 0;
  }

  .header {
    ${headerStyle}
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;

    @media only screen and (min-width: 768px) {
      padding: 2rem 0;
    }

    .item-container {
      width: 100%;

      .item-content-container {
        @media only screen and (min-width: 768px) {
          display: flex;
        }
      }

      .left {
        @media only screen and (min-width: 768px) {
          padding-right: 7.5rem;
          flex-direction: row-reverse;
        }

        @media only screen and (min-width: 1440px) {
          padding-right: 20rem;
        }

        @media only screen and (min-width: 1920px) {
          padding-right: 30rem;
        }
      }

      .right {
        @media only screen and (min-width: 768px) {
          padding-left: 7.5rem;
        }

        @media only screen and (min-width: 1440px) {
          padding-left: 20rem;
        }

        @media only screen and (min-width: 1920px) {
          padding-left: 30rem;
        }
      }

      .img-container {
        align-items: center;
        background-position: center;
        background-size: cover;
        color: white;
        display: flex;
        font-weight: 600;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        min-height: 15rem;
        letter-spacing: 0.1rem;
        word-break: break-word;
        text-decoration: none;
        padding: 1rem;

        @media only screen and (min-width: 768px) {
          min-width: 15rem;
          min-height: 5rem;
        }

        .item-title {
          font-size: 1.25rem;
          font-weight: 600;
          letter-spacing: 0.1rem;
          text-transform: uppercase;

          @media only screen and (min-width: 768px) {
            font-size: 1rem;
          }
        }
      }

      .text-content-container {
        background: #efefef;
        padding: 2rem;
        text-align: center;

        @media only screen and (min-width: 768px) {
          display: flex;
          align-items: center;
          min-height: 15rem;
        }

        .rise {
          font-weight: 600;
        }
      }
    }
  }

  .members {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 3rem auto;

    @media only screen and (min-width: 768px) {
      gap: 2rem;
      flex-direction: row;
      padding: 2rem;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .contributors {
    padding: 2rem;
    column-count: 2;
    column-fill: auto;
    margin: 0 auto;
    margin-top: 3.5rem;
    margin-bottom: 2.75rem;
    min-width: fit-content;
    max-width: 60%;

    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const cards = [
  {
    id: 0,
    align: "right",
    img: WhoWeAreImg,
    title: "Who We Are",
    content: (
      <p>
        <span className="rise">RISE Magazine</span> is a unique magazine that
        focuses on the sport of personal self development. It targets an
        audience that ranges from all demographics, gender, ages, vocations and
        educational backgrounds. Readers are taken through a journey using a
        modern approach to self improvement.
      </p>
    ),
  },
  {
    id: 1,
    align: "left",
    img: WhyReadRiseImg,
    title: "Why Read Rise",
    content: (
      <p>
        With authors and contributors sharing wisdom in colloquial terms rather
        than antiquated academic jargon, this individualized approach to
        motivation, inspiration, leadership and knowledge will help anyone
        communicate effectively with relevant topics. Never be left out of a
        conversation as you will have quick gems of knowledge to execute in
        areas such as Business, Technology, Wellness, Culture, Creativity,
        Lifestyle, Wealth and Personal Development.
      </p>
    ),
  },
  {
    id: 2,
    align: "right",
    img: WhyWeDoItImg,
    title: "Why We Do It",
    content: (
      <p>
        This community is meant to raise the overall status and standards of its
        member's contributions. No one's story is too small or too big.
        Everyone's story is someone's inspiration. Read, consume, be inspired
        and contribute to RISE. Be one of the Real Individuals Sharing
        Excellence.
      </p>
    ),
  },
  {
    id: 3,
    align: "left",
    img: OurVisionImg,
    title: "What's Our Vision",
    content: (
      <p>
        <span className="rise">RISE Magazine</span> was created to leverage the
        skills, knowledge and expertise of real people who are passionate about
        changing the world for the better. Whether it's explaining technology in
        plain english, sharing tips on winning over a large audience, sharing
        life hacks to optimize our everyday lives, or simply getting inspired by
        the struggles and triumphs of real people like you and I, you will find
        value within the pages of RISE Magazine.
      </p>
    ),
  },
];

const renderContent = () => {
  return cards.map((item) => {
    const style = {
      backgroundImage: `url(${item.img})`,
    };

    const getContainerClassName = () =>
      item.align === "left"
        ? "item-content-container left"
        : "item-content-container right";

    return (
      <div className="item-container" key={item.id}>
        <div className={getContainerClassName()}>
          <div style={style} className="img-container">
            <p className="item-title">{item.title}</p>
          </div>
          <div className="text-content-container">{item.content}</div>
        </div>
      </div>
    );
  });
};

function About() {
  return (
    <Wrapper>
      <div className="header">About</div>
      <div className="content-container">{renderContent()}</div>
      <Team />
      <Contributors />
    </Wrapper>
  );
}

export default About;
