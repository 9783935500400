export const navlinks = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About Us",
    path: "/about",
  },
  {
    id: 3,
    name: "Articles",
    path: "#",
  },
  {
    id: 4,
    name: "Archives",
    path: "/archives",
  },
  {
    id: 5,
    name: "Contact Us",
    path: "#",
  },
];
