import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 2rem;

  @media only screen and (min-width: 1024px) {
    padding: 3rem 10rem;
  }

  @media only screen and (min-width: 1920px) {
    padding: 3rem 15rem;
  }

  .section-title-container {
    .section-title {
      font-size: 1.25rem;
      text-align: center;

      @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 2rem;
        text-align: left;
      }
    }
  }
`;

function Container({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

export default Container;
