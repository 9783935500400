import React from "react";
import styled from "styled-components";
import bannerImgDesktop1 from "assets/banner-1-desktop.png";
import bannerImgDesktop2 from "assets/banner-2-desktop.png";
import bannerImgDesktop3 from "assets/banner-3-desktop.png";
import bannerImgMobile1 from "assets/banner-1-mobile.png";
import bannerImgMobile2 from "assets/banner-2-mobile.png";
import bannerImgMobile3 from "assets/banner-3-mobile.png";
import { Swiper, SwiperSlide } from "swiper/react";

const Wrapper = styled.div`
  overflow: hidden;
  padding-top: 5.5rem;

  @media only screen and (min-width: 1024px) {
    padding-top: 0;
  }

  img {
    width: 100%;
  }

  .swiper-slide,
  .swiper-slide-active {
    margin-bottom: -4px;
  }

  .mobile {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  .desktop {
    display: none;

    @media only screen and (min-width: 768px) {
      display: initial;
    }
  }
`;

function HeroCarousel() {
  return (
    <Wrapper>
      <Swiper slidesPerView={1} loop={true}>
        <SwiperSlide>
          <img src={bannerImgMobile1} alt="" className="mobile" />
          <img src={bannerImgDesktop1} alt="" className="desktop" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bannerImgMobile2} alt="" className="mobile" />
          <img src={bannerImgDesktop2} alt="" className="desktop" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bannerImgMobile3} alt="" className="mobile" />
          <img src={bannerImgDesktop3} alt="" className="desktop" />
        </SwiperSlide>
      </Swiper>
    </Wrapper>
  );
}

export default HeroCarousel;
