import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from "globalStyles";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import HomePage from "pages/Home";
import BlogPage from "pages/Blog";
import ArchivesPage from "pages/Archives";
import AboutPage from "pages/About";
import "swiper/css";
import AnchorButton from "components/common/AnchorButton";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BASE_API_URL}/graphql`,
  cache: new InMemoryCache(),
});


function App() {

  return (
    <ApolloProvider client={client}>
      <GlobalStyle />
      <AnchorButton />
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/archives/:slug" element={<BlogPage />} />
          <Route exact path="/archives" element={<ArchivesPage />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

export default App;
