import React from "react";
import Container from "components/common/Container";
import styled from "styled-components";
import nailedItImg from "assets/nailed-it.png";
import placeholderImg from "assets/author-img-placeholder.png";
import starRatingPlaceholderImg from "assets/star-rating.png";
import verifiedBadge from "assets/verified-badge.png";

const Wrapper = styled.div`
  align-items: center;
`;

const ReviewsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  @media only screen and (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    margin: 2rem 0;
    gap: 1rem;
  }
`;

const Carousel = styled.div`
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 100%;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
`;

const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Review = styled.div`
  .review-info {
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
      gap: 0;
    }
  }

  .reviewer-img-container {
    position: relative;

    @media only screen and (min-width: 768px) {
      margin-right: 2rem;
    }
  }

  .reviewer-img {
    height: 4rem;
    width: auto;

    @media only screen and (min-width: 768px) {
      height: 5rem;
    }
  }

  .verified-badge {
    position: absolute;
    right: 0;
    bottom: 5px;
    height: 1.25rem;
    width: auto;

    @media only screen and (min-width: 768px) {
      height: 1.5rem;
    }
  }

  .review-details {
    display: flex;
    flex-direction: column;
  }

  .reviewer-rating {
    display: flex;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 0.9rem;

    @media only screen and (min-width: 768px) {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }

  .review {
    font-style: italic;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: center;

    @media only screen and (min-width: 768px) {
      font-size: 1rem;
      text-align: left;
    }
  }

  .reviewer-name,
  .pipe-divider {
    margin-right: 1rem;
  }

  .reviewer-name {
    font-weight: 600;
  }

  .star-rating {
    height: 0.9rem;
    width: auto;

    @media only screen and (min-width: 768px) {
      height: 1rem;
    }
  }

  hr {
    margin: 1rem 0;
  }
`;

const reviewsData = [
  {
    id: 1,
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget leo accumsan, venenatis lacus in, elementum odio. Duis sed purus vel ipsum gravida accumsan non sed tellus. Ut vel magna nulla. Aenean vitae tortor purus. Sed in turpis a lacus.",
    reviewer: "ReviewerName 1",
    img: placeholderImg,
    rating: starRatingPlaceholderImg,
  },
  {
    id: 2,
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget leo accumsan, venenatis lacus in, elementum odio. Duis sed purus vel ipsum gravida accumsan non sed tellus. Ut vel magna nulla. Aenean vitae tortor purus. Sed in turpis a lacus.",
    reviewer: "ReviewerName 2",
    img: placeholderImg,
    rating: starRatingPlaceholderImg,
  },
  {
    id: 3,
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget leo accumsan, venenatis lacus in, elementum odio. Duis sed purus vel ipsum gravida accumsan non sed tellus. Ut vel magna nulla. Aenean vitae tortor purus. Sed in turpis a lacus.",
    reviewer: "ReviewerName 3",
    img: placeholderImg,
    rating: starRatingPlaceholderImg,
  },
];

function Reviews() {
  const reviews = () => {
    return reviewsData.map((review, index) => {
      return (
        <Review key={index}>
          <div className="review-info">
            <div className="reviewer-img-container">
              <img className="reviewer-img" src={review.img} alt="" />
              <img className="verified-badge" src={verifiedBadge} alt="" />
            </div>
            <div className="review-details">
              <p className="review">{`"${review.review}"`}</p>
              <div className="reviewer-rating">
                <p className="reviewer-name">{review.reviewer}</p>
                <p className="pipe-divider">|</p>
                <img className="star-rating" src={review.rating} alt="" />
              </div>
            </div>
          </div>
          {index === reviewsData.length - 1 ? null : <hr />}
        </Review>
      );
    });
  };

  return (
    <Container>
      <Wrapper>
        <div className="section-title-container">
          <p className="section-title">Reviews</p>
          <hr />
        </div>
        <ReviewsContentContainer>
          <Carousel>
            <img src={nailedItImg} alt=""/>
          </Carousel>
          <ReviewsContainer>{reviews()}</ReviewsContainer>
        </ReviewsContentContainer>
      </Wrapper>
    </Container>
  );
}

export default Reviews;
