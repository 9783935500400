import { gql } from "@apollo/client";

export default gql`
  query {
    teams (sort: "createdAt:ASC") {
      data {
        id
        attributes {
          first_name
          last_name
          img {
            data {
              attributes {
                url
              }
            }
          }
          role
        }
      }
    }
  }
`;
