import React, { useState } from "react";
import styled from "styled-components";
import ancHovered from "assets/rise-anchor-hovered.svg";

const Wrapper = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    bottom: 4rem;
    right: 4rem;
  }

  @media only screen and (min-width: 1440px) {
    bottom: 6rem;
    right: 6rem;
  }

  img {
    width: 4rem;
    height: auto;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.65);

    @media only screen and (min-width: 1440px) {
      width: 5rem;
    }

    :hover {
      background: rgba(255, 255, 255, 0.85);
    }
  }
`;

function AnchorButton() {
  const [show, setShow] = useState(false);

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 700) {
      setShow(true);
    } else {
      setShow(false);
    }
  });

  if (!show) return null;

  return (
    <Wrapper onClick={scrollToTop}>
      <img src={ancHovered} alt="" />
    </Wrapper>
  );
}

export default AnchorButton;
