import { useQuery } from "@apollo/client";
import React from "react";
import ContributorQry from "../gql/ContributorQry";
import styled from "styled-components";

const ContributorWrapper = styled.div`
  display: flex;
  margin-bottom: 0.75rem;

  .text {
    text-transform: uppercase;
    letter-spacing: 0.005rem;

    .name {
      font-weight: 500;
    }
  }
`;

const Contributor = ({ contributor }) => {
  const { first_name, last_name, contribution } = contributor?.attributes;

  return (
    <ContributorWrapper>
      <p className="text">
        <span className="name">
          {first_name} {last_name}
        </span>{" "}
        - {contribution}
      </p>
    </ContributorWrapper>
  );
};

function Contributors() {
  const { data } = useQuery(ContributorQry);
  const contributors = data?.contributors.data;

  const renderContributors = () => {
    return contributors?.map((contributor) => (
      <Contributor contributor={contributor} />
    ));
  };

  return (
    <>
      <div className="header">The Contributors</div>
      <div className="contributors">{renderContributors()}
      <p className="more">...and more!</p></div>
    </>
  );
}

export default Contributors;
