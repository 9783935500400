import { useQuery } from "@apollo/client";
import BlogCategoriesQry from "components/common/gql/BlogCategoriesQry";
import FirstBlogQry from "components/common/gql/FirstBlogQry";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Blogs from "./Blogs";
import useStores from "stores/useStores";
import { observer } from "mobx-react-lite";
import { headerStyle } from "../About/About";

const Wrapper = styled.div`
  padding-top: 5.5rem;

  @media only screen and (min-width: 1024px) {
    padding-top: 0;
  }

  .header {
    ${headerStyle}
  }

  .container {
    display: flex;
    gap: 1.5rem;
    min-height: fit-content;

    @media only screen and (min-width: 768px) {
      padding: 0 2.5rem;
    }

    @media only screen and (min-width: 1024px) {
      padding: 0 7.5rem;
    }

    @media only screen and (min-width: 1440px) {
      padding: 0 10rem;
    }

    @media only screen and (min-width: 1920px) {
      padding: 0 15rem;
    }
  }

  .filters {
    padding: 1rem 2rem;
    width: fit-content;

    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  .hidden {
    display: none;
  }

  .filter-sidebar-open {
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    position: fixed;
    min-height: 100vh;
    width: 100vw;

    .filter-sidebar {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      min-height: 100vh;
      padding: 1rem 2rem;
      background-color: white;
      width: 45%;
      overflow-y: auto;
      position: relative;

      .close-sidebar-btn {
        position: absolute;
        right: 2rem;
        top: 1.125rem;
      }

      .sidebar-group {
        background-color: white;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .sidebar-title {
          font-size: 1.25rem;
          font-weight: 500;
        }

        .ctg-options {
          display: flex;
          font-size: 0.85rem;
          flex-direction: column;
          gap: 0.25rem;

          .ctg,
          .ctg-active {
            cursor: pointer;
            word-break: break-word;
            max-width: fit-content;
            padding: 0.25rem 1rem;
          }

          .ctg-active {
            background: blue;
            border-radius: 2rem;
            color: white;
          }
        }

        .year-options {
          display: flex;
          font-size: 0.85rem;
          flex-direction: column;
          gap: 0.5rem;

          .yr {
            cursor: pointer;
            width: fit-content;
            padding: 0.25rem 1rem;
          }
        }
      }
    }
  }

  .sidebar {
    display: none;

    @media only screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 0;
      height: fit-content;

      .sidebar-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .sidebar-title {
          font-size: 1.75rem;
          font-weight: 500;
        }

        .ctg-options {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .ctg,
          .ctg-active {
            cursor: pointer;
            word-break: break-word;
            max-width: fit-content;
            padding: 0.25rem 1rem;
          }

          .ctg-active {
            background: blue;
            border-radius: 2rem;
            color: white;
          }
        }

        .year-options {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .yr {
            cursor: pointer;
            width: fit-content;
            padding: 0.25rem 1rem;
          }
        }
      }
    }
  }

  .blogs-container {
    padding: 2rem;
    background-color: lightgrey;
    width: 100%;
  }
`;

function Archives() {
  const { uiStore } = useStores();
  const { setArchivesFilter, blogIds, setBlogIds } = uiStore;

  const { data } = useQuery(BlogCategoriesQry);
  const { data: firstBlog } = useQuery(FirstBlogQry);

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

  useEffect(() => {
    if (isFilterSidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isFilterSidebarOpen]);

  const firstCreatedBlogDate = firstBlog?.blogs.data[0].attributes.createdAt;
  const firstCreatedBlogYear = moment(firstCreatedBlogDate).format("YYYY");
  const yearNow = moment(new Date()).format("YYYY");

  const handleCategoryFilter = (ctgId) => {
    setIsFilterSidebarOpen(false);
    if (blogIds.includes(ctgId)) {
      setArchivesFilter(null);
      setBlogIds();
    } else {
      setArchivesFilter({
        filters: {
          category: {
            id: {
              in: [ctgId],
            },
          },
        },
      });
      setBlogIds(ctgId);
    }
  };

  const renderCategories = () => {
    if (data) {
      const categoriesData = data.categories.data;
      const categoryOptions = categoriesData.map((ctg) => {
        const category = ctg.attributes.category;
        const categoryId = ctg.id;
        return (
          <p
            onClick={() => handleCategoryFilter(categoryId)}
            className={blogIds.includes(categoryId) ? "ctg-active" : "ctg"}
            key={categoryId}
          >
            {category}
          </p>
        );
      });

      return <div className="ctg-options">{categoryOptions}</div>;
    }
  };

  const renderArchiveYears = () => {
    if (firstBlog) {
      const years = yearNow - firstCreatedBlogYear + 1;

      const yearOptions = [...Array(years).keys()].map((key) => (
        <p className="yr" key={yearNow - key}>
          {yearNow - key}
        </p>
      ));

      return <div className="year-options">{yearOptions}</div>;
    }
  };

  const sidebarGroups = [
    {
      title: "Categories",
      content: renderCategories(),
    },
    {
      title: "Archives",
      content: renderArchiveYears(),
    },
  ];

  const renderSideBarGroups = () => {
    return sidebarGroups.map((grp) => {
      return (
        <div className="sidebar-group" key={grp.title}>
          <p className="sidebar-title">{grp.title}</p>
          {grp.content}
          <hr />
        </div>
      );
    });
  };

  return (
    <Wrapper>
      <div className="header">Archives</div>
      <div className={isFilterSidebarOpen ? "filter-sidebar-open" : "hidden"}>
        <div className="filter-sidebar">
          <p
            className="close-sidebar-btn"
            onClick={() => setIsFilterSidebarOpen(false)}
          >
            x
          </p>
          {renderSideBarGroups()}
        </div>
      </div>
      <div className="filters">
        <p onClick={() => setIsFilterSidebarOpen(true)}>Filter</p>
      </div>
      <div className="container">
        <div className="sidebar">{renderSideBarGroups()}</div>
        <div className="blogs-container">
          <Blogs />
        </div>
      </div>
    </Wrapper>
  );
}

export default observer(Archives);
