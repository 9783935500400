import React from "react";
import logo from "assets/r-logo-word.svg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import fbIcon from "assets/socmed-fb.png";
import igIcon from "assets/socmed-ig.png";
import twIcon from "assets/socmed-tw.png";
import ytIcon from "assets/socmed-yt.png";

const Wrapper = styled.div`
  display: none;

  @media only screen and (min-width: 1024px) {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: space-between;
    padding: 0 10rem;
    position: absolute;
    height: 6.5rem;
    width: calc(100% - 20rem);

    .div {
      display: flex;
      width: 33%;
    }

    .center {
      justify-content: center;
    }

    .end {
      justify-content: flex-end;

      img {
        cursor: pointer;
        margin-left: 0.5rem;
        width: 1.5rem;
        height: auto;
      }
    }

    .logo {
      height: 5rem;
      width: auto;
    }
  }
  @media only screen and (min-width: 1920px) {
    padding: 0 15rem;
    width: calc(100% - 30rem);
  }
`;

function Topbar() {
  return (
    <Wrapper>
      <div className="div start"></div>
      <div className="div center">
        <NavLink to="/">
          <img src={logo} alt="rise-logo" className="logo" />
        </NavLink>
      </div>
      <div className="div end">
        <a
          href={`${process.env.REACT_APP_FB_LINK}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={fbIcon} alt="fb" />
        </a>
        <a
          href={`${process.env.REACT_APP_IG_LINK}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={igIcon} alt="ig" />
        </a>
        <a
          href={`${process.env.REACT_APP_TW_LINK}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twIcon} alt="tw" />
        </a>
        <a
          href={`${process.env.REACT_APP_YT_LINK}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ytIcon} alt="yt" />
        </a>
      </div>
    </Wrapper>
  );
}

export default Topbar;
